/* ==========================================================================
  main.js
========================================================================== */

'use strict';
/* global fitvids, cssVars */

/**
 * Init responsive videos
 */
fitvids();

/**
 * Init CSS vars ponyfill
 */
cssVars({});

/**
 * Shriking header
 */
function scrollFunction() {

	const siteHeader = document.getElementById("masthead");

	if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
		siteHeader.classList.add("site-header--shrinked");
	} else {
		siteHeader.classList.remove("site-header--shrinked");
	}
}

window.onscroll = function() {
  scrollFunction();
};

/**
 * Initialize slider on section alternate module
 */
if (document.querySelectorAll('.section-alternate__slider').length) {

  document.addEventListener( 'DOMContentLoaded', function () {

    new Splide( '.section-alternate__slider', {
			type : 'loop',
			autoplay: 'pause',
      perPage : 1,
      perMove : 1,
			pagination: false
    }).mount();

  });

}
